div#BoiteConnexion {
    height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: top;
    background-size: cover;
  }
  
  div#BoiteConnexion > input {
    height: 5%;
    width: 30%;
    margin: 2%;
    background-color: rgb(154, 132, 89);
    color: black;
  }

  div#BoiteConnexion > input::placeholder {
    color: black;
  }
  
  div#BoiteConnexion > button#Connecter {
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    border-radius: 10px;
    font-size: 2vh;
    margin-top: 1vh;
    cursor: pointer;
  }
  
  div#BoiteConnexion > a {
    font-size: 2vh;
  }

  div#BoiteConnexion > input {
    font-size: 2vh;
  }