/**
* hr styling
*/

/* rpgui hr */
.rpgui-content hr {
    display:block;
    border:0px;
    height:10px;
    background: url("img/hr.png") repeat-x top left;
}

/* rpgui golden hr */
.rpgui-content hr.golden {
    display:block;
    border:0px;
    height:10px;
    background: url("img/hr-golden.png") no-repeat top left;
    background-size: 100% 100%;
}
