/**
* Paragraphs and headers while inside an rpgui container.
*/


/* default gui header */
.rpgui-content h1
{
	/* color and border */
	color: white;
    text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
    font-size: 1.14em;

	/* center text */
	text-align: center;

	/* padding */
	padding: 0 0 0 0;
	margin: 7px 7px 17px 7px;
}


/* default gui header2 */
.rpgui-content h2
{
	/* color and border */
	color: white;
    text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
    font-size: 1.25em;

	/* center text */
	text-align: center;

	/* padding */
	padding: 0 0 0 0;
	margin: 7px 7px 17px 7px;
}

/* default gui header3 */
.rpgui-content h3
{
	/* color and border */
	color: white;
	font-weight: 1;
    text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
    font-size: 1.15em;
    text-decoration: underline;

	/* center text */
	text-align: center;

	/* padding */
	padding: 0 0 0 0;
	margin: 7px 7px 17px 7px;
}

/* default gui header4 */
.rpgui-content h4
{
	/* color and border */
	color: white;
	font-weight: 1;
	text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
    font-size: 1.0em;
    text-decoration: underline;

	/* center text */
	text-align: center;

	/* padding */
	padding: 0 0 0 0;
	margin: 7px 7px 17px 7px;
}

/* default p */
.rpgui-content p
{
	/* color and border */
	color: white;
    text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
	font-size: 1.0em;
	/* line-height: 22px; */
}

/* default span */
.rpgui-content span
{
	/* color and border */
	color: white;
    text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
	font-size: 1.0em;
	/* line-height: 22px; */
}

/* default gui link */
.rpgui-content a
{
	/* color and border */
	color: yellow;
    text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
	font-size: 1.0em;
	/* line-height: 22px; */
	text-decoration: none;
}
/* default gui link */
.rpgui-content a:hover
{
	text-decoration: underline;
}

/* default gui label */
.rpgui-content label
{
	/* color and border */
	color: white;
    text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
	font-size: 1.0em;
	/* line-height: 20px; */
	display: inline;
}

/* default gui label */
.rpgui-content li
{
	/* color and border */
	margin-left:20px;
	color: white;
    text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
	font-size: 1.0em;
	/* line-height: 22px; */
}

