div#BoiteButton {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-size: cover;
  height: 85vh;
  }
  
  div#BoiteButtonGauche {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 84vh;
    width: 40%;
  }

  div#BoiteButtonGauche > div#Bouton{
    max-height: 15vh;
    height: 10vw;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  div#BoiteButtonGauche > div#Bouton > a > img{
    height: 100%;
  }


   /*enlever qd useless*/
   div#BoiteButtonGauche > div#BoutonDesactive{ 
    max-height: 15vh;
    height: 10vw;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  div#BoiteButtonGauche > div#BoutonDesactive > a > img{
    height: 100%;
    filter: grayscale(100%);
    cursor: default;
  }
  /*enlever qd useless*/
  

  div#BoiteButtonDroite {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 84vh;
    width: 40%;
  }

  div#BoiteButtonDroite > div#Bouton{
    max-height: 15vh;
    height: 10vw;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  div#BoiteButtonDroite > div#Bouton > a > img{
    height: 100%;
  }


  /*enlever qd useless*/
  div#BoiteButtonDroite > div#BoutonDesactive{ 
    max-height: 15vh;
    height: 10vw;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  div#BoiteButtonDroite > div#BoutonDesactive > a > img{
    height: 100%;
    filter: grayscale(100%);
    cursor: default;
  }
  /*enlever qd useless*/
  