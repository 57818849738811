div#BoiteJButton {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-size: cover;
    height: 85vh;

    & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 84vh;
        width: 40%;

        & > .button {
            max-height: 15vh;
            height: 10vw;
            width: 100%;
            display: flex;
            justify-content: center;
            cursor: pointer;

            img {
                height: 100%;
            }

            &.disable{
                cursor: unset;
                img{
                    filter: grayscale(100%);
                }
            }
        }
    }

}