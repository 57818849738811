@font-face {
    font-family: Washington;
    src: url('./fonts/WashingtonText.ttf');
}

body {
    font-family: Washington;

    * {
        font-family: Washington;
    }

    margin: 0px;


    .side{
        height: calc(84vh - 4em) !important;
        display: flex;
        flex-direction: column;
        gap: 2em;
        margin: 2em 0 2em 0;
        .button-menu{
            text-decoration: none;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            p{
                color: black;
                text-decoration: none;
                font-size: min(5vh, 2em);
                margin: 0;
            }
            max-height: 6em;
            height: 100%;
            background-image: url("images/bouton_menu.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            &.disable{
                filter: grayscale(100%);
            }
        }
    }

    .parametre-audio{
        display: flex;
        flex-direction: row;
        gap: 3px;
        z-index: 13;
        position: fixed;
        bottom: 0;
        right: 0;
        transform: translateX(calc(100% - 4vh - 9px));
        transition: all 1s;
        
        &:hover{
            transform: translateX(0);
        }

        .audio-volume{
            height: 4vh;
            padding: 3px;
    
            display: flex;
            flex-direction: row;
            gap: 3px;
    
            &::before{
                content: "";
                display: block;
                width: 4vh;
                //background-color: violet;
                background-image: url("./images/iconmonstr-sound-thin.svg");
                background-size: contain;
                background-repeat: no-repeat;
            }
    
            &.lv1::before{
                background-image: url("./images/iconmonstr-sound-thin-lv1.svg");
            }
    
            &.muted::before{
                background-image: url("./images/iconmonstr-sound-off-thin.svg");
            }
    
            input{
                height: 4vh;
            }
        }
    }
}