.encyclo{

    a{
        &:link, &:visited{
            color: rgb(99, 35, 33);
        }
        &:hover, &:focus{
            color: brown;
        }
    }

    img.piece {
        height : 2vh;
        padding-left: 3px;
        padding-right: 3px;
    }

    table {
        border-collapse:collapse;
        margin: auto;
    }

    td {
        border: 1px solid #333333;
        padding: 2px 5px 2px 5px;
        text-align:center;
        font-size: 2vh;
    }

    .titre {
        font-weight: bold;
    }

    ol, ul{
        counter-reset: item;
        list-style-type: circle;
    }

    ol > li {
        counter-increment: item
    }

    ol > li::marker {
        content: counters(item, ".") " ";
        font-size: 3vh;
    }

    ol#markervh > li::marker {
        font-size: 2vh;
    }

    h2 {
        font-size: 3vh;
    }

    div > ol > li > ul > li {
        font-size: 2vh;
    }

    h3, h4, p, ul#listeunite > li {
        font-size: 2vh;
        list-style-type: circle;
    }

    table {
        width: 100%;
    }

    td#td05 {
        background-color: lightcoral;
    }

    td#td1 {
        background-color: yellow;
    }

    td#td2 {
        background-color: lightgreen;
    }

    td#td3 {
        background-color: rgba(0, 128, 0, 0.778);
    }

    td#td4 {
        background-color: rgba(0, 100, 0, 0.868);
    }

}