div#BoitePatch {
    display: flex;
    justify-content: center;
    background-size: 100% 100%;
  }

  div#BoitePatch > div#ScrollPatch {
    width: 75vw;
    height: 85vh;
    overflow-y: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;
  }
    
  div#BoitePatch > div#ScrollPatch::-webkit-scrollbar {
    display: none;
  }