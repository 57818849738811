div#BoiteEncyclopedie {
  display: flex;
  justify-content: center;
  background-size: 100% 100%;
}
  
div#BoiteEncyclopedie > div#ScrollEncyclopedie {
  width: 75vw;
  height: 85vh;
  overflow-y: scroll;
  -ms-overflow-style: none; 
  scrollbar-width: none;
  scroll-behavior: smooth;
}
  
div#BoiteEncyclopedie > div#ScrollEncyclopedie::-webkit-scrollbar {
  display: none;
}