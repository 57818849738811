/**
* Rules for the slider.
*/

/* regular slider stuff */

/* slider container */
.rpgui-slider-container
{
    height: 20px;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    position: relative;
}

/* slider left edge */
.rpgui-slider-left-edge
{
    position: absolute;
    height: 20px;
    width: 20px;
    left: 0px;
    background-image: url('img/slider-left.png');
    background-size: 100% 100%;
}

/* slider right edge */
.rpgui-slider-right-edge
{
    position: absolute;
    height: 20px;
    width: 20px;
    right: 0px;
    background-image: url('img/slider-right.png');
    background-size: 100% 100%;
}

/* slider background track */
.rpgui-slider-track
{
    position: absolute;
    height: 20px;
    left: 0;
    right: 0;
    background-image: url('img/slider-track.png');
    background-repeat: repeat-x;
    background-size: 24px 100%;
}

/* the part of the slider that moves and indicates the value */
.rpgui-slider-thumb
{
    position: absolute;
    height: 30px;
    width: 15px;
    margin-top: -5px;
    left: 40px;
    background-image: url('img/slider-thumb.png');
    background-size: 100% 100%;
}

/* golden slider stuff */

/* golden slider container */
.rpgui-slider-container.golden
{
    height: 30px;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    position: relative;
}

/* golden slider left edge */
.rpgui-slider-left-edge.golden
{
    position: absolute;
    height: 30px;
    width: 30px;
    left: 0px;
    background-image: url('img/slider-left-golden.png');
    background-size: 100% 100%;
}

/* golden slider right edge */
.rpgui-slider-right-edge.golden
{
    position: absolute;
    height: 30px;
    width: 30px;
    right: 0px;
    background-image: url('img/slider-right-golden.png');
    background-size: 100% 100%;
}

/* golden slider background track */
.rpgui-slider-track.golden
{
    position: absolute;
    height: 30px;
    left: 0;
    right: 0;
    background-image: url('img/slider-track-golden.png');
    background-repeat: repeat-x;
    background-size: 40px 100%;
}

/* golden the part of the slider that moves and indicates the value */
.rpgui-slider-thumb.golden
{
    position: absolute;
    height: 36px;
    width: 18px;
    margin-top: -4px;
    left: 40px;
    background-image: url('img/slider-thumb-golden.png');
    background-size: 100% 100%;
}
