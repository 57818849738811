div#BoiteCreateAcc {
    height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: bottom;
    background-size: cover;
    background-position: top;
  }
  
  div#BoiteCreateAcc > input {
    height: 5%;
    width: 30%;
    margin: 2%;
    background-color: rgb(154, 132, 89);
    color: black;
  }

  div#BoiteCreateAcc > input::placeholder {
    color: black;
  }
  
  div#BoiteCreateAcc > button#Creer {
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    border-radius: 10px;
    font-size: 2vh;
    margin-top: 1vh;
    cursor: pointer;
  }

  div#BoiteCreateAcc > a {
    font-size: 2vh;
  }
  
  div#BoiteCreateAcc > input {
    font-size: 2vh;
  }