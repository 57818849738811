/**
* customized divs (containers) and framed objects (background and frame image).
*/

/* game div without background image*/
.rpgui-container
{
	/* position style and default z */
	/* position: fixed; */
	z-index: 10;

	overflow: show;
}

/* game div with background image*/
.rpgui-container.framed
{
	/* border */
	border-style: solid;
	border-image-source: url("img/border-image.png");
	border-image-repeat: repeat;
	border-image-slice: 6 6 6 6;
	border-image-width: 18px;
	border-width: 15px;
	padding: 12px;

	/* internal border */
	box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

	/* background */
	background: url("img/background-image.png") repeat repeat;
	background-clip: padding-box;
	background-origin: padding-box;
	background-position: center;
}

/* game div with bois background image*/
.rpgui-container.framed-bois
{
	/* border */
	border-style: solid;
	border-image-source: url("img/border-image-bois.png");
	border-image-repeat: repeat;
	border-image-slice: 75 75 75 75;
	border-image-width: 15px;
	border-width: 15px;
	padding: 15px;

	/* internal border */
	box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

	/* background */
	background: url("img/background-image-bois.png") repeat repeat;
	background-clip: padding-box;
	background-origin: padding-box;
	background-position: center;
	background-size: contain;
}

/* game div with bois background image*/
.rpgui-container.framed-bois-border-only
{
	/* border */
	border-style: solid;
	border-image-source: url("img/border-image-bois.png");
	border-image-repeat: repeat;
	border-image-slice: 75 75 75 75;
	border-image-width: 15px;
	border-width: 15px;
	padding: 15px;

	/* internal border */
	box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

	/* background */
	background: url("img/background-image.png") repeat repeat;
	background-clip: padding-box;
	background-origin: padding-box;
	background-position: center;
	background-size: contain;
}



/* game div with golden background image*/
.rpgui-container.framed-golden
{
	/* border */
	border-style: solid;
	border-image-source: url("img/border-image-golden.png");
	border-image-repeat: repeat;
	border-image-slice: 4 4 4 4;
	border-image-width: 18px;
	border-width: 15px;
	padding: 12px;

	/* internal border */
	box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

	/* background */
	background: url("img/background-image-golden.png") repeat repeat;
	background-clip: padding-box;
	background-origin: padding-box;
	background-position: center;
}

/* game div with golden2 background image*/
.rpgui-container.framed-golden-2
{
	/* border */
	border-style: solid;
	border-image-source: url("img/border-image-golden2.png");
	border-image-repeat: repeat;
	border-image-slice: 8 8 8 8;
	border-image-width: 18px;
	border-width: 15px;
	padding: 12px;

	/* internal border */
	box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

	/* background */
	background: url("img/background-image-golden2.png") repeat repeat;
	background-clip: padding-box;
	background-origin: padding-box;
	background-position: center;
}

/* game div with soft grey background image*/
.rpgui-container.framed-grey
{
	position: relative;

	/* border */
	border-style: solid;
	border-image-source: url("img/border-image-grey.png");
	border-image-repeat: repeat;
	border-image-slice: 3 3 3 3;
	border-image-width: 7px;
	border-width: 7px;
	padding: 12px;

	/* internal border */
	box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

	/* background */
	background: url("img/background-image-grey.png") repeat repeat;
	background-clip: padding-box;
	background-origin: padding-box;
	background-position: center;
}
