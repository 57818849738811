@import "./rpg_style/app.scss";

body{
    //font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    //background-color: rgb(30, 31, 33);
    background-color: #222;
}

.gameapp{
    display: flex;
    justify-content: center;
    align-items: center;

    p{
        font-size: 2em;
    }
}

.player-display{
    font-family: Washington;

    position: fixed;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    gap : 70%;

    

    .player{
        @media (max-width : 700px) {
            font-size: smaller;
            padding: 2px;
        }
    
        @media (max-width : 600px) {
            padding: 2px;
            font-size: xx-small;
        }

        &.bleu{
            /* color: blue;
            border: solid 1px blue;*/
            background-color: rgba(0, 0, 102, 0.4) !important; 
            background-image: url("./images/banniere_bleue.png");
            background-position: bottom;
            background-size: contain;
            background-repeat: no-repeat;
        }
        &.rouge{
            /* color: red;
            border: solid 1px red;*/
            background-color: rgba(88, 0, 0, 0.4) !important;
            background-image: url("./images/banniere_rouge.png");
            background-position: bottom;
            background-size: contain;
            background-repeat: no-repeat;
        }

        /* border: solid 1px black;
        background-color: rgba(206, 206, 206, 0.4);
 */
        font-family: Washington;
           
        height: calc(100% - 28px);
        width: 100%;

        margin: 3px;
        padding: 10px;

        text-align: center;

        display: flex;
        flex-direction: column;
        align-items: center;

        justify-content: flex-start;

        order: 2;

        overflow: hidden;
        &.you{
            &::before{
                content: "Vous :";
            }
            order: 1;
        }

        &.other-player{
            .stats{
                margin-bottom: 100px;
            }
        }

        .timer, h3, h4, p{
            font-size: 200%;
            @media (max-width : 1200px) {
                font-size: 130%;
            }
        }

        div > button{
            font-size: 120% !important;
        }

        

        p>img.piece{
            height: 1.2em;
        }

        .readybutton{
            margin: 20px 0;
            /* height: 2.5em; */
            width: 90%;
            font-size: 120%
        }

        .stats{
            @media (max-width : 1200px) {
                font-size: 70%;
            }
            @media (max-width : 100px) {
                font-size: 50%;
            }
            flex-grow: 1;

            display: flex;
            flex-direction: column;
            justify-content: flex-end;


            table{
                
                & *{
                    font-family: Washington;
                }

                th{
                    font-weight: lighter;
                }
            }
        }
    }
}

.salon-info{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3em;

    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 3%;
}

.game{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-size: cover !important;

    /* width: 67%;
    height: 96%; */

    padding: 0.5%;
    border: 1px solid black;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    .winner-displayer{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;

        max-width: 800px;
        max-height: 300px;

        width: 80%;
        height: 80%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        z-index: 10;

        p, a{
            font-size: 3em;
        }
    }

    .square{
        width: 80%;
        padding-bottom: 80%;

        position: relative;

        .gameGrid{
            

            padding: 0;

            background-image: var(--image);
            background-position: center;
            background-size: cover;
        
            box-shadow: 0px 0px 15px rgba($color: #000000, $alpha: 0.6);
        
            position: absolute;
            width: 100%;
            height: 100%;
        
            display: grid;
            grid-template: repeat(17, 1fr) / repeat(17, 1fr);
        
            
        
            .spawnZone{
                &.bleu{
                    border: solid 2px blue;
                }
                &.rouge{
                    border: solid 2px red;
                }
            }
        
            .unit{
                & *{
                    font-size: 88%;
                    font-family: Arial, Helvetica, sans-serif !important;
                    text-shadow: none;
                }


                image-rendering: auto;
                 
                border: solid 0.5px rgba(145, 145, 145, 0.575);
                
        
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
        
                margin-right: -1px;
                margin-bottom: -1px;
        
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
        
                &.click{
                    cursor: pointer;
                }

                
    
                &:hover{
                    background-color: rgba(145, 145, 145, 0.575);
                    border: solid 0.5px rgba(255, 255, 255, 0.842);
    
                    .hp{
                        display: block;
                    }
                }

                &.focus{
                    &:hover {
                        cursor: pointer;
                        background-color: rgba(165, 0, 0, 0.212);
                    }
            
                    margin-right: -1px;
                    margin-bottom: -1px;
            
                    border: solid 1px rgb(165, 0, 0);

                    z-index: 100;
                }
    
                p{
                    margin: 0;
                    color: rgb(255, 255, 255);
                }
    
                .hp{
                    display: none;
                }
        
                .hp-bars{
                    align-self: flex-start;
                    width: var(--size);
                    height: 15%;
        
                    background-color: #00a800;
                }
        
                
        
                &.Epéiste{
                    &.rouge{
                        background-image: url("./images/symbole_epeistes_rouge.png");
                    }
                    &.bleu{
                        background-image: url("./images/symbole_epeistes_bleu.png");
                    } 
                }
        
                &.Archer{
                    &.rouge{
                        background-image: url("./images/symbole_archers_rouge.png");
                    }
                    &.bleu{
                        background-image: url("./images/symbole_archers_bleu.png");
                    } 
                }
        
                &.Cavalier{
                    &.rouge{
                        background-image: url("./images/symbole_cavaliers_rouge.png");
                    }
                    &.bleu{
                        background-image: url("./images/symbole_cavaliers_bleu.png");
                    } 
                }
        
                &.Lancier{
                    &.rouge{
                        background-image: url("./images/symbole_lanciers_rouge.png");
                    }
                    &.bleu{
                        background-image: url("./images/symbole_lanciers_bleu.png");
                    } 
                }
        
                &.Catapulte{
                        position: relative;
                        &::after{
                            content: "";
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            z-index: -1;
                            background-size: contain;
                        }
                        &.r0::after{
                            transform: rotate(180deg);
                        }

                        &.r1::after{
                            transform: rotate(225deg);
                        }

                        &.r2::after{
                            transform: rotate(270deg);
                        }

                        &.r3::after{
                            transform: rotate(315deg);
                        }

                        &.r4::after{
                            transform: rotate(0deg);
                        }

                        &.r5::after{
                            transform: rotate(45deg);
                        }

                        &.r6::after{
                            transform: rotate(90deg);
                        }

                        &.r7::after{
                            transform: rotate(135deg);
                        }

                        &.rouge::after{
                            background-image: url("./images/symbole_catapultes_rouge.png");
                        }
                        &.bleu::after{
                            background-image: url("./images/symbole_catapultes_bleu.png");
                        } 

                        
                        
                    
                }
        
                &.Général{
                    &.rouge{
                        background-image: url("./images/symbole_general_rouge.png");
                    }
                    &.bleu{
                        background-image: url("./images/symbole_general_bleu.png");
                    } 
                }
            }
        
    
            .err-message{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
        
                height: 15%;
                width: 60%;
        
                font-size: 200%;
    
                border: solid 1px rgb(124, 0, 0);
                border-radius: 20px;
        
                color: rgb(148, 1, 1);
        
                display: flex;
                align-items: center;
                justify-content: center;
        
                box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.281);
        
                background-color: rgba(255, 0, 0, 0.212);
        
        
                p{
                    margin: 0;
                }
            }
        }
    }
    

    .movement-panel{
        height: 80%;
        width: 20%;

        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;

        .button{
            width: 35%;
            margin: 0;
            padding: 0;

            padding-bottom: 35%;

            /* border: 1px solid black; */
            border-image-width: 20%;
            border-width: 1px;
            position: relative;

            &.fleche > div{
                background-image: url('./images/icon-mouvement-fleche.png');
            }
            &.rotate > div{
                background-image: url('./images/icon-mouvement-rotate.png');
            }

            &>div{
                margin: 15%;

                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
            

            &:hover > div{
                background-color: rgba(206, 206, 206, 0.4);
                cursor: pointer;
            }
            
            &>div{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                color: rgba(145, 145, 145, 0);
            }
        }
    }

    
}



.unit-selector{
    &::before{
        content: "Unités";
        display: block;
        position: absolute;
        top: 0;
        opacity: 1;
        transition: all 1s;
    }

    font-size: 2em;

    position: fixed;
    bottom: -15%;
    left: 0;
    width: 100%;
    height: 20%;

    z-index: 100;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    transition: all 0.75s;

    background-color: rgba($color: #000000, $alpha: 0.3);

    &:hover{
        bottom: 0;

        &::before{
            opacity: 0;
        }
    }

    .unit{
        display: flex;
        flex-direction: column;
        align-items: center;

        cursor: pointer;
        padding: 1%;

        transition: .3s all;

        &>*{
            margin: 0;
        }

        &>h4{
            font-weight: normal;
            font-style: italic;
        }

        &>p{
            font-size: smaller;
        }

        &>p>img.piece{
            height: 1.2em;
        }

        &:hover{
            transform: scale(1.1);
        }

        &:active{
            transition: 0.1s all;
            transform: scale(0.9);
        }

        &.selected{
            &>*{
                font-weight: bolder;
            }
        }
    }
}