/**
* different cursor graphics
*/



/* default cursor important */
/* this rule is for when you specifically request this cursor class */
.rpgui-cursor-default
{
    cursor: url("img/cursor/default.png"), auto !important;
}

/* default cursor, not important, for all elements without any other rule. */
.rpgui-content,
label
{
    cursor: url("img/cursor/default.png"), auto;
}




/* pointer / hand cursor important */
/* this rule is for when you specifically request this cursor class */
.rpgui-cursor-point,
.rpgui-cursor-point *
{
    cursor: url("img/cursor/point.png") 10 0, auto !important;
}

/* pointer / hand cursor, not important, for all elements that have pointer by-default */
.rpgui-content a,
.rpgui-content button,
.rpgui-button,
.rpgui-slider-container,
.rpgui-content input[type=radio].rpgui-radio + label,
.rpgui-list-imp,
.rpgui-dropdown-imp,
.rpgui-content input[type=checkbox].rpgui-checkbox + label
{
    cursor: url("img/cursor/point.png") 10 0, auto;
}




/* for input / text selection important */
/* this rule is for when you specifically request this cursor class */
.rpgui-cursor-select,
.rpgui-cursor-select *
{
    cursor: url("img/cursor/select.png") 10 0, auto !important;
}

/* for input / text selection, not important, for all elements that have pointer by-default */
.rpgui-cursor-select,
.rpgui-content input,
.rpgui-content textarea
{
    cursor: url("img/cursor/select.png") 10 0, auto;
}

/* for grabbing stuff */
/* this rule is for when you specifically request this cursor class */
.rpgui-cursor-grab-open,
.rpgui-cursor-grab-open *
{
    cursor: url("img/cursor/grab-open.png") 10 0, auto !important;
}

/* for grabbing stuff */
/* this rule is for when you specifically request this cursor class */
.rpgui-cursor-grab-close,
.rpgui-cursor-grab-close *
{
    cursor: url("img/cursor/grab-close.png") 10 0, auto !important;
}