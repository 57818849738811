div#Patchs> div {
    display: flex;
    text-align: center;
}

div#Patchs> div> h2 {
    white-space: nowrap;
    margin-right: 3vw;
    font-size: 3vh;
    margin-top: 3vh;
    margin-bottom: 1.2vh;
}

div#Patchs> h3 {
    font-size: 2.7vh;
    margin-top: 1.2vh;
    margin-bottom: 1.2vh;
}

div#Patchs> h4 {
    font-size: 2.4vh;
    margin-top: 1vh;
    margin-bottom: 1vh;
    margin-left: 1.5vw;
}

div#Patchs> p> h5 {
    font-size: 2.1vh;
    margin-top: 0.8vh;
    margin-bottom: 0.8vh;
    margin-left: 3vw;
}

div#Patchs> div> div {
    width:100%;
    height: 0px;
    border: 1px solid black;
    align-self: center;
}

div#Patchs> p {
    font-size: 2vh;
    margin-top: 1vh;
    margin-bottom: 1vh;
}

div#Patchs> p > p{
    font-size: 2vh;
    text-indent: 1vw;
}

div#Patchs> p > img{
    width : 25vw;
}

div#Patchs> p >span#Barré {
    text-decoration: line-through;
    text-decoration-thickness: 0.05vh;
}

div#Patchs> p >span#Vert {
    color: green;
}

div#Patchs> p >span#Jaune {
    color: yellow;
}

div#Patchs> p >span#Rouge {
    color: red;
}
