div#BoiteMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-size: cover;
    height: 85vh;
  }
  
  div#BoiteMenu > div#Part1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    height: 84vh;
    justify-content: space-evenly;
  }

  div#BoiteMenu > div#Part1 > div#Bouton{
    height: 15vh;
    width: 75vh;
    display: flex;
    justify-content: center;
  }
  
  div#BoiteMenu > div#Part1 > div#Bouton > a > img{
    height: 100%;
  }
  
  div#BoiteMenu > div#Part2 {
    width: 50%;
    height: 84vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 5vw;
    margin-left: 8vw;
    color: lightgrey;
    gap: 0em;
  }

  div#BoiteMenu > div#Part2 > div#ScrollBox {
    height: 80%;
    overflow: scroll;
    font-size: 2vh;
    background:  rgba(0, 0, 0, 0.3);
    border-radius: 0px 0px 20px 20px;
    padding-left: 2%;
    padding-right: 2%;
    -ms-overflow-style: none; 
    scrollbar-width: none;
  }

  div#BoiteMenu > div#Part2 > div#ScrollBox > p > p{
    margin: 0px;
  }

  div#BoiteMenu > div#Part2 > div#ScrollBox > p > img {
    width : 100%;
  }

  div#BoiteMenu > div#Part2 > div#ScrollBox::-webkit-scrollbar {
    display: none;
  }
  
  div#BoiteMenu > div#Part2 > h2 {
    font-size: 3vh;
    margin-bottom: 0px;
    background:  rgba(0, 0, 0, 0.3);
    border-radius: 20px 20px 0px 0px;
  }
  
  div#BoiteMenu > div > a {
    display: flex;
    justify-content: center;
  }
  
  div#BoiteMenu > div > a > img {
    width: 70%;
  }
  