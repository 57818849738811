/**
* global content styling
*/

/* game div with background image*/
.rpgui-content
{
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    position: fixed;
    overflow: hidden;
    font-size: 0.8em;
}

/* general rules to apply on anything inside the content */
.rpgui-content * {

    /* remove outline effect for input elements etc */
    outline: none;

	/* prevent dragging */
	/* user-drag: none; */
	-webkit-user-drag: none;

	/* prevent text selecting */
	-webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);

	/* pixelated enlargement filter (to keep the pixel-art style when enlarging pictures) */
	-ms-interpolation-mode: nearest-neighbor;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -webkit-crisp-edges;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;

	/* default font */
    font-family: Washington;
}
