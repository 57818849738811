.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(154, 132, 89);
  }

  .Header > img.Logo {
    height: 12vh;
    margin-left: 1.5vh;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
  }
  
  .Header > p {
    font-size: 1.5vw;
  }

  button#Deconnexion {
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    border-radius: 10px;
    font-size: 1.5vw;
    margin-right: 0.5vw;
    cursor: pointer;
  }

  