.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(154, 132, 89);
  }
  
  .Header > img.Logo {
    height: 12vh;
    margin-left: 1.5vh;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
  }
  
  .Header > h1 {
    font-size: 6vh;
    margin: 0px;
  }
  
  .Header > div#Discord > a > img.Discord {
    width: 100%;
  }
  
  .Header > div#Discord > a.DBox {
    width: 80%;
  }

  .Header > div#Discord{
    width: 15vh;
    height: 15vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }