/**
* style for radioes
*/

/* radio box */

.rpgui-content input[type=radio].rpgui-radio
{
    display: none;
}

.rpgui-content input[type=radio].rpgui-radio + label
{
    background: url('img/radio-off.png') no-repeat;
	line-height: 24px;
    display: inline-block;
    background-size: auto 100%;
    padding-left: 34px;
    height: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.rpgui-content input[type=radio].rpgui-radio:checked + label
{
    background: url('img/radio-on.png') no-repeat;
	line-height: 24px;
    display: inline-block;
    background-size: auto 100%;
    padding-left: 34px;
    height: 24px;
}

/* golden radio */

.rpgui-content .rpgui-radio.golden + label
{
    background: url('img/radio-golden-off.png') no-repeat !important;
    background-size: auto 100% !important;
}

.rpgui-content .rpgui-radio.golden:checked + label
{
    background: url('img/radio-golden-on.png') no-repeat !important;
    background-size: auto 100% !important;
}