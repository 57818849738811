/**
* Styling for buttons
*/

/* button style */
.rpgui-button {

	/* hide button default stuff */
	background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    overflow: hidden;
    outline:none;

	/* background */
	background: url("img/button.png") no-repeat no-repeat;
	background-clip: padding-box;
	background-origin: padding-box;
	background-position: center;
	background-size: 100% 100%;

	/* font size */
	font-size: 1.0em;

	/* default size and display */
    max-width: 100%;
    min-width: 140px;
    height: 60px;
    display: inline-block;

    /* padding */
    padding-left: 35px;
    padding-right: 35px;
}

/* button hover */
.rpgui-button.hover,
.rpgui-button:hover {

	background-image: url("img/button-hover.png");

}

/* button clicked */
.rpgui-button.down,
.rpgui-button:active {

	background-image: url("img/button-down.png");
}

/* golden button stuff */
.rpgui-button.golden p
{
    display: inline-block
}

/* golden button style */
.rpgui-button.golden {

	/* hide button default stuff */
	background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    overflow: hidden;
    outline:none;

	/* background */
	background: url("img/button-golden.png") no-repeat no-repeat;
	background-clip: padding-box;
	background-origin: padding-box;
	background-position: center;
	background-size: 100% 80%;

	/* default size and display */
    max-width: 100%;
    min-width: 140px;
    height: 60px;
    display: inline-block;

    /* padding */
    padding-top: 5px;
    padding-left: 35px;
    padding-right: 35px;
    overflow: visible;
}

/* button hover */
.rpgui-button.golden.hover,
.rpgui-button.golden:hover {

	background-image: url("img/button-golden-hover.png");
}

/* button clicked */
.rpgui-button.golden.down,
.rpgui-button.golden:active {

	background-image: url("img/button-golden-down.png");
}

.rpgui-button.golden:before
{

    white-space: nowrap;
    display: inline-block;
    content: "";
    width: 34px;
    display: block;
    height: 110%;
    background: transparent url('img/button-golden-left.png') no-repeat right center;
    background-size: 100% 100%;
    margin: 0 0 0 0;
    left: 0px;
    float: left;
    margin-left: -46px;
    margin-top: -5%;
}

.rpgui-button.golden:after
{

    white-space: nowrap;
    display: block;
    content: "";
    width: 34px;
    height: 110%;
    background: transparent url('img/button-golden-right.png') no-repeat left center;
    background-size: 100% 100%;
    margin: 0 0 0 0;
    right: 0px;
    float: right;
    margin-right: -46px;
    margin-top: -5%;
}

/*
.rpgui-button.golden:hover:before {

    background-image: url('img/button-golden-left-hover.png');
}

.rpgui-button.golden:hover:after {

    background-image: url('img/button-golden-right-hover.png');
}
*/