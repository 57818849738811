/**
* for disabled elements
*/

/* disabled object */
.rpgui-disabled,
.rpgui-content :disabled,
.rpgui-content input[type=radio]:disabled + label,
.rpgui-content input[type=checkbox]:disabled + label,
.rpgui-content input[type=range]:disabled + .rpgui-slider-container,
.rpgui-content :disabled + .rpgui-dropdown-imp,
.rpgui-content :disabled + .rpgui-dropdown-imp + .rpgui-dropdown-imp,
.rpgui-content :disabled + .rpgui-list-imp
{
  cursor: url("img/cursor/default.png"), auto;
  -webkit-filter: grayscale(1);
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: url(#greyscale);
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  filter: gray;
  color: #999;
}